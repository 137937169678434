import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useClickOutside } from '@digital-gov/ui-utils';
import { ButtonLink, ButtonLinkType } from 'components/Button';
import { Download } from 'components/Icon';
import { Spinner, SpinnerKind } from 'components/Spinner';
import { Icon, IconChevronDown, IconSize } from 'componentsL/Icon';
import { Tooltip } from 'componentsL/Tooltip';
import { basename } from 'store/config';
import {
  FgiskiFileKind,
  IndicatorMethodologyType,
  IndicatorSpecificationType,
  useGetMethodologyUrlLazyQuery
} from 'store/graphql';
import s from './CardDownloadsBlock.module.scss';

type CardDownloadsBlockProps = {
  disabled?: boolean;
  filename?: string;
  path?: string;
  scopeId?: number;
  indicatorId?: number;
  methodologies?: IndicatorMethodologyType[];
  specifications?: IndicatorSpecificationType[];
};

export const CardDownloadsBlock = ({
  disabled,
  filename,
  path,
  specifications,
  scopeId,
  indicatorId,
  methodologies
}: CardDownloadsBlockProps) => {
  const userMenuRef = useRef<HTMLDivElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [targetNode, setTargetNode] = useState<HTMLDivElement | null>(null);

  const [getDownloadUrl, { loading }] = useGetMethodologyUrlLazyQuery({
    fetchPolicy: 'network-only'
  });
  const handleMethodologyDownload = async () => {
    if (methodologies?.length && indicatorId) {
      const linkNode = document.createElement('a');
      const { data } = await getDownloadUrl({
        variables: {
          input: {
            kind: FgiskiFileKind.Methodology,
            entityId: indicatorId,
            scope: methodologies[0].scope,
            fileIndex: methodologies[0].idx
          }
        }
      });
      const downloadedURL = data?.fgiskiFilePresignedUrl;
      if (!downloadedURL) {
        console.error('Ошибка запроса URL на скачивание файла');
        return;
      }
      linkNode.href = downloadedURL;
      linkNode.click();
    }
  };

  const handleSpecificationDownload = async () => {
    if (specifications?.length && indicatorId) {
      const linkNode = document.createElement('a');
      const { data } = await getDownloadUrl({
        variables: {
          input: {
            kind: FgiskiFileKind.Specification,
            entityId: indicatorId,
            scopeId: scopeId,
            scope: specifications[0].scope,
            fileIndex: specifications[0].idx,
            periodId: specifications[0].periodId
          }
        }
      });
      const downloadedURL = data?.fgiskiFilePresignedUrl;
      if (!downloadedURL) {
        console.error('Ошибка запроса URL на скачивание файла');
        return;
      }
      linkNode.href = downloadedURL;
      linkNode.click();
    }
  };

  const methodologyDownload = methodologies && methodologies.length > 0;
  const specificationDownload = specifications && specifications.length > 0;

  useClickOutside(userMenuRef.current, () => setMenuOpen(false));
  return (
    <div ref={userMenuRef} className={s.CardDownloadsBlock}>
      <div
        ref={setTargetNode}
        className={clsx(s.CardDownloadsBlock__row, menuOpen ? s.CardDownloadsBlock__open : null)}
        onClick={() => setMenuOpen((prevState) => !prevState)}>
        <Icon
          className={clsx(s.CardDownloadsBlock__menuArrow, {
            [s.CardDownloadsBlock__menuArrow_active]: menuOpen
          })}
          icon={IconChevronDown}
          size={IconSize.Small}
        />
        Скачать
      </div>
      {menuOpen && (
        <Tooltip
          classes={{ content: s.Tooltip__content }}
          showArrow={false}
          targetNode={targetNode}
          tooltipOptions={{
            placement: 'bottom-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0]
                }
              }
            ]
          }}>
          <div className={s.Tooltip__links}>
            {!methodologyDownload && (
              <div className={s.Tooltip__link}>
                <ButtonLink
                  type={ButtonLinkType.Bright}
                  disabled={disabled}
                  download
                  href={encodeURI(`${basename}/indicators/${path ? path + '/' : ''}${filename}`)}>
                  Методика
                </ButtonLink>
              </div>
            )}
            {methodologyDownload && (
              <div className={s.Tooltip__link}>
                {loading && <Spinner kind={SpinnerKind.Inline} />}
                {!loading && (
                  <ButtonLink type={ButtonLinkType.Bright} onClick={handleMethodologyDownload}>
                    Методика
                  </ButtonLink>
                )}
              </div>
            )}
            {specificationDownload && (
              <div className={s.Tooltip__link}>
                {loading && <Spinner kind={SpinnerKind.Inline} />}
                {!loading && (
                  <ButtonLink type={ButtonLinkType.Bright} onClick={handleSpecificationDownload}>
                    Расшифровка
                  </ButtonLink>
                )}
              </div>
            )}
          </div>
        </Tooltip>
      )}
    </div>
  );
};
